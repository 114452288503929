

import { Component, Vue } from 'vue-property-decorator';
import ProviderFilter from '@/modules/deep-analysis/components/hotel/actions/provider-filter.vue';
import CompareToFilter from '@/modules/deep-analysis/components/hotel/actions/compare-to-filter.vue';
import ShowByFilter from '@/modules/deep-analysis/components/hotel/actions/show-by-filter.vue';
import ExcelBtn from '../deep-analysis-excel.vue';

@Component({
    components: {
        ProviderFilter,
        CompareToFilter,
        ShowByFilter,
        ExcelBtn,
    },
})
export default class DeepAnalysisActions extends Vue {
}
