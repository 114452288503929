

import { Component, Prop, Vue } from 'vue-property-decorator';
import Currency from '@/modules/common/components/currency.vue';
import ASSESSMENT_TYPES from '@/modules/common/constants/assessments-types.constant';
import VALUE_TYPE from '@/modules/deep-analysis/constants/value-type.constant';
import type { StatisticsData } from '@/modules/deep-analysis/interfaces/statistics-data.interface';
import PriceFilter from '@/modules/common/filters/price.filter';

@Component({
    filters: {
        PriceFilter,
    },
    components: {
        Currency,
    },
})
export default class DeepAnalysisIndicator extends Vue {
    @Prop({
        type: Object,
        default: () => ({}),
    })
    data!: StatisticsData;

    @Prop({
        type: String,
    })
    valueType!: VALUE_TYPE;

    @Prop({
        type: String,
    })
    currency!: string;

    @Prop({
        type: Boolean,
        required: false,
        default: false,
    })
    isCompared!: boolean;

    @Prop({
        type: Boolean,
    })
    skeleton!: boolean;

    get color() {
        if (this.isCompared || !this.data.assessment) return '';
        return this.data.assessment === ASSESSMENT_TYPES.GOOD ? 'green' : 'red';
    }

    get haveStar() {
        return this.data.hasStar;
    }

    get havePrice() {
        return this.data.value !== null && this.valueType === 'price';
    }

    get formattedValue() {
        return this.data.value === null ? '-' : PriceFilter(this.data.value);
    }

    get unit() {
        return this.data.value !== null && this.valueType === 'percent' ? '%' : '';
    }

    public PriceFilter = PriceFilter;
}

